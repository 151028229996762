import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';

export default class RecruitListViewModel {
  constructor() {
    this.boardData = {
      title:'채용공고 목록',
      drawDataList: [
        {
          title:'No.',
          width:'60px',
          value:'id',
          isSorting: true
        },
        {
          title:'이미지',
          width:'50px',
          value:'thumbnail',
          img:{
            width:'36px',
            height:'36px',
            position:{
              x: 'center',
              y:'0'
            },
            size:'cover'
          },
        },
        {
          title:'제목',
          width:'',
          value:'title',
          isEllip: true,
          isAlignLeft: true
        },
        // {
        //   title:'내용',
        //   width:'',
        //   value:'contents',
        //   isEllip: true,
        //   isAlignLeft: true
        // },
        {
          title:'조회수',
          width:'60px',
          value:'viewCount',
          isEllip: true,
          isAlignRight: true,
          emptyValueText:'-'
        },
        {
          title:'상태',
          width:'100px',
          value:'status',
          filter:{
            name:'convertIdToText',
            value:'blog_status'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'blog_status'
            },
            badgeFill: false
          },
        },
        {
          title:'최종수정일시',
          width:'144px',
          value:'updatedAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm' },
          isMobileTitle:true
        },
        {
          title:'공유',
          width:'100px',
          value:'status',
          buttonCondition: [
            { condition:'NORMAL', text:'링크복사', size:'small', style:'secondary_border', disabled:false, onClickEvent: (data) => this.onClickCopy(data) },
          ],
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }

  init(query){
    const { pageSize, page } = query;
    if(pageSize) {
      this.searchParams.pageSize = Number(pageSize);
    }
    if(page) {
      this.searchParams.page = Number(page);
    }
    this.getRecruitList();
  }
  onClickCopy(rowData){
    const domain = 'https://www.firfin.family'
    const path = '/recruit/detail/'
    const param = rowData.id;
    const linkData = `${domain}${path}${param}`;
    window.navigator.clipboard.writeText(linkData).then(() => {
      store.dispatch('commonToast/fetchToastStart', `채용공고 링크가 복사되었습니다`);
    });
  }
  onClickRow(rowId){
    router.push({
      name: 'LANDING_RECRUIT_DETAIL',
      params: { id : rowId},
      query : {
        pageSize : this.searchParams.pageSize,
        page : this.searchParams.page,
      }
    });
  }
  onSearch(){
    this.getRecruitList();
  }
  getRecruitList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.RECRUIT_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.data;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}